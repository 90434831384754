













import { Component } from 'vue-property-decorator';
import { BaseComponent } from '@/mixins/base-component';
import { UserStoreModule } from '@/store/modules/user';
const version = require('../../../package.json').version;

@Component
export default class SignComponent extends BaseComponent {
    get Version() {
        return version;
    }

    get ProjectName(): string {
        return UserStoreModule.CorpName;
    }

    get ProjectLogo(): string {
        return UserStoreModule.Logo;
    }

    get ProjectPicture(): string {
        return UserStoreModule.LoginImage;
    }
}
