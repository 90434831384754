var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sign-component" },
    [
      _c("img", { staticClass: "bg", attrs: { src: _vm.ProjectPicture } }),
      _c(
        "div",
        { staticClass: "title" },
        [
          _c("img", { staticClass: "logo", attrs: { src: _vm.ProjectLogo } }),
          _c("a-divider", {
            staticClass: "divider",
            attrs: { type: "vertical" }
          }),
          _c("div", [_vm._v(_vm._s(_vm.ProjectName))])
        ],
        1
      ),
      _c("router-view"),
      _c("span", { staticClass: "version" }, [_vm._v(_vm._s(_vm.Version))])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }